
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator"
import { NetworkStatus } from "@apollo/client/core"
import {
  Survey,
  SurveyQuery,
  PublishSurveyMutation,
  UnpublishSurveyMutation,
  UnpublishSurveyMutationMutation,
  PublishSurveyMutationMutation,
} from "@/gql"
import Loader from "@/components/widgets/common/Loader.vue"
import SurveyPreview from "@/components/surveys/SurveyPreview.vue"
import NewSurveyForm from "@/components/forms/NewSurveyForm.vue"
import SurveyEditor from "@/components/forms/SurveyEditor.vue"
import SurveyResponse from "./SurveyResponse.vue"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"
import { useUrl } from "@/hooks/useUrl"
import SurveyActivity from "./SurveyActivity.vue"

@Component({
  components: {
    Loader,
    SurveyPreview,
    NewSurveyForm,
    SurveyEditor,
    SurveyResponse,
    SurveyActivity,
  },

  apollo: {
    survey: {
      query: SurveyQuery,
      variables() {
        return { ...this.queryVars }
      },
      skip: true,
      result(result) {
        if (result.networkStatus === NetworkStatus.ready) {
          this.loadingSurvey = false
          this.loadingError = false
        }
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loadingSurvey = false
          this.loadingError = true
        } else if (error.networkError) {
          this.networkError = true
        }
      },

      update(data) {
        this.published = data.surveys?.data[0]?.state === "published"
        return data.surveys?.data[0] || null
      },
    },
  },
})
export default class SurveyFormDialog extends Vue {
  @Prop({ required: true }) readonly value!: boolean
  @Prop({ required: true }) readonly selectedSurveyId!: string

  tab: number | null = null
  survey: Survey | null = null
  showSurveyForm = false
  loadingSurvey = false
  loadingError = false
  networkError = false
  published = false
  saved = false
  saving = false
  showAddSurvey = true
  _timeout: number | undefined
  showPublishSuccessModal = false
  error = false

  get refetchQueries() {
    return [
      {
        query: SurveyQuery,
        variables: {
          ...this.queryVars,
        },
      },
    ]
  }

  get queryVars(): FilterType {
    return {
      filter: { idIn: this.selectedSurveyId },
    }
  }

  get surveyLink(): string {
    const { href } = useUrl(this.$routes.CompleteSurvey, { id: this.selectedSurveyId })
    return href
  }

  @Watch("value")
  onValueChange() {
    this.showSurveyForm = this.value

    if (this.value && !this.selectedSurveyId) {
      this.survey = null
    }
  }

  @Watch("selectedSurveyId")
  onFetchSurvey() {
    if (this.selectedSurveyId) {
      this.loadingSurvey = true
      this.navigateToPreview()
      return (this.$apollo.queries.survey.skip = false)
    }
  }

  @Emit("input")
  @Emit("close")
  onClose() {
    this.tab = null
    this.showAddSurvey = true

    return false
  }

  editDetails() {
    this.showAddSurvey = true
    this.tab = 0
  }

  async onNewSurveySaved(survey: Survey) {
    this.survey = survey
    this.$emit("onSelectedSurveryChange", survey)
    this.showAddSurvey = false
  }

  onSaved(): void {
    this.saved = true
    this.saving = false

    clearTimeout(this._timeout)

    this._timeout = window.setTimeout(() => {
      this.saved = false
    }, 4000)
  }

  onSavingSurvey(): void {
    this.saving = true
  }

  onError(): void {
    this.error = true
    this.saving = false
    clearTimeout(this._timeout)

    this._timeout = window.setTimeout(() => {
      this.error = false
    }, 4000)
  }

  navigateToPreview() {
    this.showAddSurvey = false
    this.tab = 1
  }

  handlePublish() {
    if (!this.survey) return
    this.published ? this.publishSurvey() : this.unpublishSurvey()
  }

  async publishSurvey() {
    if (!this.survey) return

    this.saving = true
    const { data, errors } = await this.mutate<PublishSurveyMutationMutation>({
      mutation: PublishSurveyMutation,
      variables: {
        surveyId: this.survey.id,
      },
      done: () => this.onSaved(),
    })

    if (errors) {
      this.addError("Something went wrong")
    }

    if (data?.publishSurvey.error) {
      this.addError(data.publishSurvey.error.message)
    } else {
      this.published = true
      this.saved = true
    }
  }

  async unpublishSurvey() {
    if (!this.survey) return

    this.saving = true

    const { data } = await this.mutate<UnpublishSurveyMutationMutation>({
      mutation: UnpublishSurveyMutation,
      variables: {
        surveyId: this.survey.id,
      },
      done: () => this.onSaved(),
      error: () => {
        this.addError("Something went wrong")
      },
    })

    if (data && !data.unpublishSurvey.error) {
      this.published = false
      this.saved = true
    }
  }
}
