
import {
  SurveyQuestion,
  Survey,
  AddSurveySectionMutationMutationVariables,
  AddSurveySectionMutationMutation,
  AddSurveySectionMutation,
  UpdateSurveySectionMutation,
  UpdateSurveySectionMutationMutation,
  UpdateSurveySectionMutationMutationVariables,
  MutationAddSurveySectionArgs,
} from "@/gql"
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator"
import SortableHandle from "../widgets/sortable/SortableHandle.vue"
import SortableItem from "../widgets/sortable/SortableItem.vue"
import SortableList from "../widgets/sortable/SortableList.vue"
import ScaleInput from "@/components/fields/ScaleInput.vue"
import QuestionList from "../surveys/QuestionList.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import SurveySection from "./SurveySection.vue"
import { Question } from "@/components/surveys/QuestionList.vue"

export type Section = {
  id: string | null
  title: string
  description: string
  tempId?: string
  questions: Question[]
  surveyId?: string
}

@Component({
  components: {
    SortableList,
    SortableItem,
    SortableHandle,
    ScaleInput,
    QuestionList,
    SurveySection,
  },
})
export default class SurveyEditor extends Vue {
  @Prop() survey!: Survey
  @Prop() refetchQueries!: RefetchQueryDescription

  isSaving = false
  showDeleteDialog = false
  deleteLoading = false
  activeQuestion: SurveyQuestion | null = null
  activeIndex: number | null = 0
  isSection = false

  defaultSection: Section = {
    id: null,
    title: "",
    description: "",
    questions: [],
  }

  sections: Section[] = []

  onAddQuestion(index: number) {
    if (this.isSection) {
      this.$refs?.[`QuestionList${index}`] &&
        (
          (this.$refs?.[`QuestionList${index}`] as Vue[] | undefined)?.[0] as QuestionList
        ).onAddQuestion()
      return
    }
    this.$refs.QuestionList && (this.$refs.QuestionList as QuestionList).onAddQuestion()
  }

  @Emit("input")
  @Emit("close")
  onCancel() {
    return false
  }

  onSaved() {
    this.$emit("saved")
  }
  onSaving() {
    this.$emit("saving")
  }

  onError() {
    this.$emit("error")
  }

  onAddSection() {
    //Check if this is the first click and there are already questions
    if (!this.isSection && this.survey.questions.length > 0) {
      this.createInitialSectionWithUngroupedQuestions()

      const ungroupedQuestionsId = this.survey.questions.map((q) => q.id)

      this.saveSection({
        ...this.sections[0],
        surveyId: this.survey.id,
        questionIds: ungroupedQuestionsId,
      })
    }

    const payload = {
      ...this.defaultSection,
      tempId: this.generateRand(10),
      title: "Untitled Section " + (this.sections.length + 1),
    }

    this.sections.push({
      ...payload,
    })

    this.saveSection({
      ...payload,
      surveyId: this.survey.id,
    })

    if (!this.isSection) this.isSection = true
  }

  async saveSection(payload: MutationAddSurveySectionArgs) {
    if (!payload.surveyId) return

    this.$emit("saving")
    const result = await this.mutate<
      AddSurveySectionMutationMutation,
      AddSurveySectionMutationMutationVariables
    >({
      mutation: AddSurveySectionMutation,
      variables: {
        ...payload,
      },
      handlePayloadErrors: true,
      refetchQueries: this.refetchQueries,
    })

    if (result?.data?.addSurveySection.section?.id) {
      this.$emit("saved")
      return
    }

    this.$emit("error")
  }

  async onUpdateSection(payload: Section) {
    if (!payload?.id) return

    this.$emit("saving")
    const result = await this.mutate<
      UpdateSurveySectionMutationMutation,
      UpdateSurveySectionMutationMutationVariables
    >({
      mutation: UpdateSurveySectionMutation,
      variables: {
        ...payload,
        sectionId: payload.id,
      },
      handlePayloadErrors: true,
      refetchQueries: this.refetchQueries,
    })

    if (result?.data?.updateSurveySection.section?.id) {
      this.$emit("saved")
      return
    }

    this.$emit("error")
  }
  @Watch("survey", { deep: true })
  onSurveyPropsChange() {
    this.populateWithExitingSections()
  }

  createInitialSectionWithUngroupedQuestions() {
    this.sections.push({
      ...this.defaultSection,
      tempId: this.generateRand(10),
      title: "Untitled Section " + (this.sections.length + 1),
      questions: this.survey.questions as unknown as Question[],
    })
  }

  populateWithExitingSections() {
    this.sections = this.survey.surveySections as unknown as Section[]
  }

  mounted() {
    if (this.survey.surveySections.length) {
      this.populateWithExitingSections()
      this.isSection = true
    }
  }
}
