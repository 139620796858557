import { render, staticRenderFns } from "./Surveys.vue?vue&type=template&id=a8c9b3b6&scoped=true&"
import script from "./Surveys.vue?vue&type=script&lang=ts&"
export * from "./Surveys.vue?vue&type=script&lang=ts&"
import style0 from "./Surveys.vue?vue&type=style&index=0&id=a8c9b3b6&prod&scoped=true&lang=scss&"
import style1 from "./Surveys.vue?vue&type=style&index=1&id=a8c9b3b6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8c9b3b6",
  null
  
)

export default component.exports