
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {},
})
export default class LabelInput extends Vue {
  @Prop({ required: true }) value!: string
  @Prop({ required: true }) index!: number
  @Prop() placeholder!: string

  form = {
    label: "",
    focus: false,
  }

  inputError = false

  @Watch("value", { immediate: true })
  onValuePropChange() {
    if (this.value) {
      this.form.label = this.value
    }
  }

  onChange() {
    if (!this.form.label) return (this.inputError = true)
    this.$emit("input", this.form.label)
    this.$emit("change", this.form.label)
    this.$emit("save", { label: this.form.label })
  }

  mounted() {
    if (!this.form.label?.length) {
      ;(this.$el.getElementsByClassName("custom--input")[0] as HTMLElement).focus()
    }
  }
}
