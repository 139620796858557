var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input--container"},[(!_vm.form.focus && _vm.form.label.trim())?_c('span',[_vm._v(" "+_vm._s(_vm.index + 1)+". ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.label),expression:"form.label",modifiers:{"trim":true}}],staticClass:"custom--input",class:{
      'grey lighten-2 pl-4': !_vm.form.focus && !_vm.form.label.trim(),
      'pl-4 grey lighten-2': _vm.form.focus,
      'pl-4': !_vm.form.focus && !!_vm.form.label.trim(),
      'pl-5 font-weight-medium': !_vm.form.focus && _vm.form.label.trim(),
      'pl-7 font-weight-medium':
        !_vm.form.focus && _vm.form.label.trim() && (_vm.index + 1).toString().length >= 2,
      shake: _vm.inputError,
    },attrs:{"type":"text","placeholder":_vm.placeholder || `${_vm.index + 1}. Enter your question here`},domProps:{"value":(_vm.form.label)},on:{"focus":function($event){_vm.form.focus = true},"blur":[function($event){_vm.form.focus = false},function($event){return _vm.$forceUpdate()}],"change":_vm.onChange,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "label", $event.target.value.trim())}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }