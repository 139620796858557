
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import {
  SurveyQuestion,
  SurveyQuestionTypeEnum,
  AddSurveyQuestionMutation,
  AddSurveyQuestionMutationMutation,
  EditSurveyQuestionMutation,
  EditSurveyQuestionMutationMutation,
  DeleteSurveyQuestionMutation,
  DeleteSurveyQuestionMutationMutation,
} from "@/gql"
import SortableHandle from "@/components/widgets/sortable/SortableHandle.vue"
import SortableItem from "@/components/widgets/sortable/SortableItem.vue"
import SortableList from "@/components/widgets/sortable/SortableList.vue"
import LabelInput from "./LabelInput.vue"
import OptionInput from "./OptionInput.vue"
import QuestionTypeInput from "./QuestionTypeInput.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

export interface InputOptionType {
  description: string
  label: string
  value: string
  score?: number
}

export type ScaleOptionType = {
  min: number
  max: number
  minLabel: string
  maxLabel: string
  customLabels?: boolean
  layout: "horizontal" | "vertical"
  labels?: { [key: string]: string }
}

export type OptionType = InputOptionType[] | ScaleOptionType

export type Question = Partial<Omit<SurveyQuestion, "options" | "id">> & {
  id: BigInt | null
  saved: boolean
  index?: number
  tempId?: string
  options?: OptionType
}

type QuestionPayload = Question & {
  surveySectionId?: string
}

@Component({
  components: {
    SortableList,
    SortableItem,
    SortableHandle,
    LabelInput,
    OptionInput,
    QuestionTypeInput,
  },
})
export default class QuestionList extends Vue {
  @Prop() readonly questionList!: Question[]
  @Prop({ required: true }) readonly surveyId!: string
  @Prop({ required: false }) readonly sectionId?: string
  @Prop() refetchQueries!: RefetchQueryDescription

  activeIndex: number | null = 0
  showDeleteDialog = false
  deleteLoading = false
  activeQuestion: Question | null = null

  defaultQuestion: Question = {
    id: null,
    questionType: SurveyQuestionTypeEnum.ShortAnswer,
    label: "",
    required: false,
    options: [],
    saved: false,
    tempId: this.generateRand(10),
  }

  questions: Question[] = []

  normalizeOptions(options: OptionType) {
    let normalizedOptions: OptionType
    let parsedOptions: OptionType

    try {
      parsedOptions = JSON.parse(options as any)
    } catch (error) {
      parsedOptions = options
    }
    if (Array.isArray(parsedOptions)) {
      normalizedOptions = parsedOptions?.map((option: InputOptionType) => ({
        ...option,
      }))
    } else {
      normalizedOptions = parsedOptions
    }

    return normalizedOptions
  }

  @Watch("questionList", { immediate: true, deep: true })
  populateForm() {
    //find unsaved questions
    const unsavedQuestions = this.questions.filter((question) => !question.saved)

    this.questions = [
      ...this.questionList.map((q) => {
        return {
          ...q,
          options: q.options && this.normalizeOptions(q.options),
          tempId: this.generateRand(10),
          saved: true,
        }
      }),
      ...unsavedQuestions,
    ]
  }

  onQuestionClick(index: number) {
    this.activeIndex = index
  }

  async updateQuestionPosition(question: Question, position: number) {
    console.log("updateQuestionPosition", question, position)
  }

  onQuestionSort(questions: Question[], { newIndex }: { [key: string]: number }) {
    this.updateQuestionPosition(questions![newIndex], newIndex)

    this.onQuestionSave(questions![newIndex], {
      position: newIndex,
    } as Question)
  }

  onAddQuestion() {
    const lastQuestion = this.questions[this.questions.length - 1]
    if (lastQuestion && !lastQuestion.label?.trim()) {
      this.addError(`Question ${this.questions.length} cannot be empty`)
      return
    }

    const newQuestion: Question = {
      ...this.defaultQuestion,
      tempId: this.generateRand(10),
    }
    this.questions = [...this.questions, newQuestion]
    //make new question active
    this.activeIndex = this.questions.length - 1
  }

  removeQuestion(question: Question) {
    const index = this.questions.findIndex((q) => q.tempId === question.tempId)
    this.questions.splice(index, 1)
  }

  onDeleteQuestion(question: Question) {
    this.activeQuestion = question
    if (question.saved) {
      this.showDeleteDialog = true
    } else {
      this.removeQuestion(question)
    }

    this.activeIndex = null
  }

  async onDeleteSavedQuestion() {
    this.deleteLoading = true

    const result = await this.mutate<DeleteSurveyQuestionMutationMutation>({
      mutation: DeleteSurveyQuestionMutation,
      variables: { surveyQuestionId: this.activeQuestion!.id },
      done: () => {
        this.deleteLoading = false
        this.showDeleteDialog = false
      },
      error: (error) => {
        this.addError(error.message)
        this.deleteLoading = false
      },
      refetchQueries: this.refetchQueries,
    })

    if (result.data && !result.data.deleteSurveyQuestion.error) {
      this.addSuccess("Question removed successfully")
      this.removeQuestion(this.activeQuestion!)
    }
  }

  updateQuestionId(question: Question, id: string) {
    this.$set(question, "id", id)
    this.$set(question, "saved", true)
    this.$emit("saved")
  }

  onDuplicateQuestion(question: Question) {
    if (question && question.label && !question.label.trim()) return

    const newQuestion: Question = {
      ...question,
      id: null,
      saved: false,
      tempId: this.generateRand(10),
    }

    this.questions = [...this.questions, newQuestion]

    this.onQuestionSave(newQuestion, newQuestion)
    //make new question active
    this.activeIndex = this.questions.length - 1
  }

  onQuestionSave(question: Question, payload: Question) {
    if (!question.label?.trim()) {
      this.addError(`Question cannot be empty`)
      return
    }

    const updatedPayload = this.sectionId
      ? { ...payload, surveySectionId: this.sectionId }
      : payload

    if (question.saved) {
      this.updateQuestion(question, updatedPayload)
    } else {
      this.createQuestion(question, updatedPayload)
    }
  }

  async createQuestion(question: Question, payload: QuestionPayload) {
    this.$emit("saving")
    const result = await this.mutate<AddSurveyQuestionMutationMutation>({
      mutation: AddSurveyQuestionMutation,
      variables: {
        ...payload,
        surveyId: this.surveyId,
        questionType: question.questionType,
        options: JSON.stringify(question.options),
      },
      error: () => {
        this.$emit("error")
      },
      refetchQueries: this.refetchQueries,
    })
    if (result.data?.addSurveyQuestion?.question) {
      this.updateQuestionId(question, result.data?.addSurveyQuestion?.question.id)
      return
    }
    this.$emit("error")
  }

  async updateQuestion(question: Question, payload: QuestionPayload) {
    this.$emit("saving")
    const result = await this.mutate<EditSurveyQuestionMutationMutation>({
      mutation: EditSurveyQuestionMutation,
      variables: {
        ...payload,
        questionId: question.id,
      },
      error: () => {
        this.$emit("error")
      },
    })
    if (result.data?.editSurveyQuestion?.question) {
      this.$emit("saved")
      return
    }
    this.$emit("error")
  }
}
