
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { SurveyFragmentFragment, UserFragmentFragment } from "@/gql"
import DonutChart from "./graphs/DonutChart.vue"
import ScaleInput from "@/components/fields/ScaleInput.vue"
import KeywordField from "../fields/KeywordField.vue"
import UserAvatar from "../profile/UserAvatar.vue"

@Component({
  components: {
    DonutChart,
    ScaleInput,
    KeywordField,
    UserAvatar,
  },
})
export default class SurveyResponse extends Vue {
  @Prop() survey!: SurveyFragmentFragment
  tab = null
  checkbox = false
  showAllResponses = false
  selectedResponseIds: string[] = []
  selectedResponses: SurveyFragmentFragment["responses"] = []

  mounted() {
    if (this.survey.responses?.length) {
      this.selectedResponseIds.push(this.survey?.responses[0].id)
    }
  }

  toggleAllResponses() {
    if (this.showAllResponses) {
      this.selectedResponseIds = this.survey.responses!.map((response) => response.id)
    } else {
      this.selectedResponseIds = []
    }
  }

  toggleIndividualResponse() {
    if (this.selectedResponseIds.length === this.survey.responses!.length) {
      this.showAllResponses = true
    } else {
      this.showAllResponses = false
    }
  }

  getOccurrence(answers: any[]) {
    return answers.reduce(function (obj, b) {
      obj[b] = ++obj[b] || 1
      return obj
    }, {})
  }

  getChoices(answers: any[]) {
    return answers.map((a) => a.choice)
  }

  get responsesByQuestionIndex() {
    return this.survey.questions.map((question) => this.getAnswers(question))
  }

  getAnswers(question: SurveyFragmentFragment["questions"][0]) {
    // Array of choices
    let answers: {
      choice: string
      user: UserFragmentFragment | undefined | null
      updatedAt: string
    }[] = []
    this.survey.responses?.map((response) =>
      response.data
        .filter((a) => a.questionId === question.id)
        .forEach((a) =>
          answers.push({ choice: a.choice, user: response.user, updatedAt: response.updatedAt })
        )
    )

    // Get aggregate answer based on question type
    switch (question.questionType) {
      case "scale":
        return {
          value: this.getOccurrence(this.getChoices(answers)),
          length: answers.length,
          answers,
        }

      case "multiple_choice":
      case "dropdown":
      case "multi_select":
      case "scored_multiple_choice":
        return {
          value: this.getOccurrence(this.getChoices(answers)),
          length: answers.length,
          answers,
        }

      case "scored_single_choice":
        // Get option description as choice
        answers = answers.map((choice) => {
          const option = (question.options as any[]).find((o) => {
            return o.label === choice
          })
          return option.description
        })

        return answers

      default:
        return answers
    }
  }

  getResponseFromId(id: string) {
    return this.survey.responses?.find((r) => r.id === id)
  }

  @Watch("selectedResponseIds") onSelectedResponsesChange() {
    this.selectedResponses = this.selectedResponseIds.map(
      (selected) => this.getResponseFromId(selected)!
    )
  }

  getSelectedAnswer(question: SurveyFragmentFragment["questions"][0]) {
    let answers: any[] = []
    this.selectedResponses?.map((response) => {
      response.data.find((a) => a.questionId === question.id)?.choice &&
        answers.push({
          choice: response.data.find((a) => a.questionId === question.id)?.choice,
          user: response.user,
          updatedAt: response.updatedAt,
        })
    })

    return answers
  }
}
