
import { Component, Vue, Watch } from "vue-property-decorator"
import {
  ListSurveyQuery,
  Survey,
  PaginationFragmentFragment,
  DeleteSurveyMutation,
  ArchiveSurveyMutation,
  SurveyFilterInput,
  ArchiveSurveyMutationMutation,
  DeleteSurveyMutationMutation,
} from "@/gql"
import NewSurveyForm from "@/components/forms/NewSurveyForm.vue"
import SurveyEditor from "@/components/forms/SurveyEditor.vue"
import Pagination from "@/components/widgets/common/Pagination.vue"
import AppPage from "@/components/layout/AppPage.vue"
import Loader from "@/components/widgets/common/Loader.vue"
import SurveyPreview from "@/components/surveys/SurveyPreview.vue"
import SurveysFeatureModal from "@/components/surveys/SurveysFeatureModal.vue"
import SurveyActivity from "@/components/surveys/SurveyActivity.vue"
import SurveyResponse from "@/components/surveys/SurveyResponse.vue"
import SearchInput from "@/components/widgets/common/SearchInput.vue"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"
import { NetworkStatus } from "@apollo/client/core"
import { useUrl } from "@/hooks/useUrl"
import SurveyFormDialog from "@/components/surveys/SurveyFormDialog.vue"

type PageState = "loading" | "loaded" | "error"

@Component({
  components: {
    AppPage,
    NewSurveyForm,
    SurveyEditor,
    SurveyPreview,
    SurveysFeatureModal,
    SurveyActivity,
    SurveyResponse,
    Pagination,
    Loader,
    SearchInput,
    SurveyFormDialog,
  },

  apollo: {
    surveys: {
      query: ListSurveyQuery,
      variables() {
        return { ...this.queryVars }
      },

      result(result) {
        if (result.networkStatus === NetworkStatus.ready) {
          this.pagination = result.data?.surveys?.pagination
          this.loadingSurveys = false
          this.loadingError = false

          if (result.data?.surveys && result.data?.surveys?.data.length === 0) {
            this.pageState = "empty"
          }
        }
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loadingSurveys = false
          this.loadingError = true

          this.pageState = "error"
        } else if (error.networkError) {
          this.networkError = true
        }
      },

      update(data) {
        return data.surveys?.data || []
      },
    },
  },
})
export default class Surveys extends Vue {
  loadingError = false
  networkError = false
  showSurveyDialog = false
  showSurvey = false
  showDeleteDialog = false
  showDeleteErrorDialog = false
  showArchiveDialog = false
  showLinkDialog = false
  selectedSurveyId: string | null = null
  surveyLinkCopied = false

  deleting = false
  archiving = false

  deleteErrorMessage = "Survey cannot be deleted it is linked to valid responses."

  pageState: PageState = "loading"

  filter: SurveyFilterInput = {}
  search: string | null = null
  loadingSurveys = true
  surveysPagination = { page: 1, per: 10, length: 1 }

  surveys: Survey[] = []
  activeSurvey: Survey | null = null

  pagination: PaginationFragmentFragment = {} as PaginationFragmentFragment

  get surveyLink(): string {
    const { href } = useUrl(this.$routes.CompleteSurvey, { id: this.activeSurvey?.scatterId })
    return href
  }

  @Watch("search")
  onFilterChange() {
    this.debounceCall(() => {
      this.filter = { search: this.search ? this.search : undefined }
      this.loadingSurveys = true
    }, 1000)
  }

  get queryVars(): FilterType {
    return {
      paginateSurveys: { page: this.surveysPagination.page, per: this.surveysPagination.per },
      filter: this.filter,
    }
  }

  onStartNewSurvey() {
    this.showSurveyDialog = true
    this.selectedSurveyId = null
    this.activeSurvey = null
  }

  @Watch("pagination.totalSize")
  paginationOpenLength() {
    this.surveysPagination.length =
      Math.ceil(this.pagination.totalSize / this.surveysPagination.per) > 0
        ? Math.ceil(this.pagination.totalSize / this.surveysPagination.per)
        : 1
  }

  async refetchQuery() {
    this.$apollo.queries.surveys.refetch()
  }

  async onRetryRequest() {
    this.$router.go(0)
  }

  onSelectedSurveryChange(survey: Survey) {
    this.selectedSurveyId = survey.scatterId
  }

  async onSurveyDelete(survey: Survey) {
    this.deleting = true

    const { data } = await this.mutate<DeleteSurveyMutationMutation>({
      mutation: DeleteSurveyMutation,
      variables: { surveyId: survey.prn },
      refetchQueries: [
        {
          query: ListSurveyQuery,
          variables: { ...this.queryVars },
        },
      ],
      done: () => {
        this.deleting = false
      },
    })

    if (data && data.deleteSurvey.error) {
      this.addError(data.deleteSurvey.error.message)

      this.showDeleteDialog = false
      this.showDeleteErrorDialog = true
      this.deleteErrorMessage = data.deleteSurvey.error.message
    } else {
      this.addSuccess("Survey deleted")

      this.surveys.splice(
        this.surveys.findIndex((s) => s.id == survey.id),
        1
      )
      this.showDeleteDialog = false
    }
  }

  async onSurveyArchive(survey: Survey) {
    this.archiving = true

    const { data } = await this.mutate<ArchiveSurveyMutationMutation>({
      mutation: ArchiveSurveyMutation,
      variables: { id: survey.prn },
      done: () => {
        this.archiving = false
      },
    })

    if (data && data.archiveSurvey.error) {
      this.addError(data.archiveSurvey.error.message)
    } else {
      this.addSuccess("Survey archived successfully")
      this.surveys.splice(
        this.surveys.findIndex((s) => s.id == survey.id),
        1
      )
      this.showArchiveDialog = false
    }
  }

  @Watch("surveysPagination.per")
  onPaginationPerChange() {
    this.paginationOpenLength()
  }

  @Watch("$route")
  onRouteChange() {
    this.toggleDialog()
  }

  mounted() {
    this.toggleDialog()
  }

  @Watch("showSurveyDialog")
  onDialogChange() {
    if (!this.showSurveyDialog && this.$route.params.id) {
      this.$router.push({ name: this.$routes.Surveys })
    }
  }

  onDeleteSurvey() {
    this.showDeleteDialog = false
  }

  onShowSurveyDialog(action: string, survey: Survey) {
    switch (action) {
      case "edit":
        this.showSurveyDialog = true
        break

      case "open":
        this.showSurveyDetails(survey)
        break

      case "results":
        this.showSurveyDetails(survey)
        break

      case "duplicate":
        break

      case "preview":
        this.showSurveyDetails(survey)
        break

      case "delete":
        this.showDeleteDialog = true
        break

      case "archive":
        this.showArchiveDialog = true
        break

      case "share":
        this.showShareSurveyLink()
        break

      default:
        this.addError(`Unknown action: ${action}`)
        break
    }

    this.activeSurvey = survey
  }

  showSurveyDetails(survey: Survey) {
    this.activeSurvey = survey
    this.selectedSurveyId = survey.scatterId
    this.showSurveyDialog = true

    this.$router.push(`${this.$route.path}/view/${this.selectedSurveyId}`)

    this.$refs.SurveyFormDialog &&
      (this.$refs.SurveyFormDialog as SurveyFormDialog).navigateToPreview()
  }

  showShareSurveyLink() {
    this.surveyLinkCopied = false
    this.showLinkDialog = true
  }

  toggleDialog() {
    if (this.$route.params.id) {
      this.selectedSurveyId = this.$route.params.id
      this.showSurveyDialog = true
    }
  }
}
