
import { Component, Vue, Prop } from "vue-property-decorator"
import { Survey, SurveyQuestion } from "@/gql"
import ScaleInput from "@/components/fields/ScaleInput.vue"

@Component({
  components: { ScaleInput },
})
export default class SurveyPreview extends Vue {
  @Prop() survey!: Survey

  get sortedQuestions() {
    return this.survey.questions.sort((a, b) => {
      if (a.position && b.position) {
        return a.position - b.position
      } else {
        return 0
      }
    })
  }

  getSectionDetails(id: string) {
    return this.survey.surveySections.find((q) => q.id === id)
  }

  ORPHAN_QUESTION_ID = "ORPHAN_QUESTION_ID"
  get GroupedQuestions() {
    return this.sortedQuestions.reduce((acc, curr) => {
      const id = curr.surveySection?.id ?? this.ORPHAN_QUESTION_ID
      if (acc[id]) {
        acc[id].push(curr)
      } else {
        acc[id] = [curr]
      }
      return acc
    }, {} as Record<string, SurveyQuestion[]>)
  }

  getNormalizedOptions(options: any) {
    try {
      return JSON.parse(options)
    } catch (error) {
      return options
    }
  }
}
