
import { ApexOptions } from "apexcharts"
import { Vue, Component, Prop } from "vue-property-decorator"
import VueApexCharts from "vue-apexcharts"

@Component({
  components: {
    VueApexCharts,
  },
})
export default class DonutChart extends Vue {
  @Prop() readonly graphData!: { [key: string]: any }

  labels: string[] = Object.keys(this.graphData)
  series = Object.values(this.graphData)

  chartOptions: ApexOptions = {
    // chart: {
    //   type: "donut",
    // },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: "12px",
              fontFamily: "Inter",
              label: "Responses",
              color: "#8C8C8C",
            },
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Inter",
              color: "#8C8C8C",
              offsetY: 10,
            },
            value: {
              show: true,
              fontWeight: "700",
              color: "#FF0008",
              fontSize: "18px",
              offsetY: -20,
            },
          },
        },
      },
    },
    noData: {
      text: "No data available",
    },
    stroke: {
      width: 1,
      colors: undefined,
    },
    yaxis: {
      show: false,
    },
    labels: this.labels,
    legend: {
      position: "right",
      fontSize: "12px",
      fontFamily: "Inter",
      offsetX: 200,
      formatter: function (val: any) {
        return val + " - " + "responses"
      },
      labels: {
        colors: "#8C8C8C",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  }
}
