import { render, staticRenderFns } from "./SurveySection.vue?vue&type=template&id=4c948c88&scoped=true&"
import script from "./SurveySection.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SurveySection.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SurveySection.vue?vue&type=style&index=0&id=4c948c88&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c948c88",
  null
  
)

export default component.exports