
import { Component, Vue, Emit } from "vue-property-decorator"

@Component
export default class SurveysFeatureModal extends Vue {
  @Emit("close")
  @Emit("input")
  onClose() {
    return false
  }

  @Emit("add")
  @Emit("input")
  onAdd() {
    return true
  }
  surveyFeatures = [
    "Create new surveys",
    "View and duplicate existing surveys",
    "View and analyse survey results",
  ]
}
