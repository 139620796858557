
import { Component, Vue, Prop } from "vue-property-decorator"
import { ActivityLog } from "@/gql"
import ActivityTimeline from "../widgets/timeline/ActivityTimeline.vue"

@Component({
  components: {
    ActivityTimeline,
  },
})
export default class SurveyActivity extends Vue {
  @Prop({ default: [] }) activityLogs!: ActivityLog[]

  loadingMore = false
  loadMoreActivity() {
    this.loadingMore = true

    setTimeout(() => {
      this.loadingMore = false
    }, 2000)
  }
}
