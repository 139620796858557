
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import { Question, OptionType, InputOptionType, ScaleOptionType } from "./QuestionList.vue"
import { SurveyQuestionTypeEnum } from "@/gql"
import ScaleInput from "@/components/fields/ScaleInput.vue"

@Component({
  components: { ScaleInput },
})
export default class OptionInput extends Vue {
  @Prop({ required: true }) value!: string
  @Prop({ required: true }) question!: Question

  @Emit("saved")
  emitSaved() {
    return true
  }

  @Emit("saving")
  emitSaving() {
    return true
  }

  defaultOption = {
    description: "",
    label: "",
    value: "",
    focus: false,
  }

  form: { questionType: SurveyQuestionTypeEnum; options: OptionType } = {
    questionType: SurveyQuestionTypeEnum.ShortAnswer,
    options: [],
  }

  onFocus(option: any) {
    this.$set(option, "focus", true)
  }

  onBlur(option: any) {
    this.$set(option, "focus", false)
  }

  normalizedOptions(options: OptionType) {
    let normalizedOptions
    if (Array.isArray(options)) {
      normalizedOptions = options?.map((option: any) => {
        const { focus, ...rest } = option
        return rest
      })
    } else {
      normalizedOptions = options
    }

    return normalizedOptions
  }

  @Watch("question", { immediate: true, deep: true })
  onQuestionPropChange() {
    this.form.questionType = this.question.questionType!
    this.form.options = this.question.options!
  }

  onOptionChange(option: any) {
    this.$set(option, "description", option.label)
    this.$set(option, "value", option.label.split(" ").join("_"))

    this.saveOptions()
  }

  saveOptions() {
    const options = this.normalizedOptions(this.form.options)

    this.$emit("input", options)

    this.$emit("save", {
      options: JSON.stringify(options),
    })
  }

  onAddOption() {
    //check if last option label is empty
    const optionsLength = (this.form.options as InputOptionType[]).length
    if (!(this.form.options as InputOptionType[])[optionsLength - 1].label.trim()) {
      this.addError(`Option ${optionsLength} cannot be empty`)
      return
    }

    if (this.form.questionType === "scored_multiple_choice") {
      ;(this.form.options as InputOptionType[]).push({
        ...this.defaultOption,
        score: optionsLength + 1,
      })
    } else {
      ;(this.form.options as InputOptionType[]).push({ ...this.defaultOption })
    }
    this.$emit("input", this.form.options)
  }

  onDeleteOption(index: number) {
    if ((this.form.options as InputOptionType[]).length <= 2) {
      this.addError("Options cannot be less than 2")
      return
    }
    ;(this.form.options as InputOptionType[]).splice(index, 1)
    this.$emit("save", {
      options: JSON.stringify(this.normalizedOptions(this.form.options)),
    })
  }

  onMinLabelChange(value: string) {
    ;(this.form.options as ScaleOptionType).minLabel = value
    this.saveOptions()
  }

  onMaxLabelChange(value: string) {
    ;(this.form.options as ScaleOptionType).maxLabel = value
    this.saveOptions()
  }

  onScaleLayoutChange(layout: "horizontal" | "vertical") {
    ;(this.form.options as ScaleOptionType).layout = layout
    this.saveOptions()
  }

  onAddScaleLabels() {
    ;(this.form.options as ScaleOptionType).customLabels = true
    this.saveOptions()
  }

  onScaleLabelChange({ index, value }: { index: number; value: string }) {
    ;(this.form.options as ScaleOptionType).labels = {
      ...((this.form.options as ScaleOptionType).labels || {}),
      [index]: value,
    }
    this.saveOptions()
  }
}
