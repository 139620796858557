
import { Component, Vue, Emit, Watch, Prop } from "vue-property-decorator"
import { AllJobTitlesQuery } from "@/gql"

import {
  CreateSurveyMutation,
  CreateSurveyMutationMutation,
  UpdateSurveyMutation,
  UpdateSurveyMutationMutation,
  SurveyFragmentFragment,
  ListSurveyQuery,
} from "@/gql"
import { FilterType } from "../widgets/common/FilterBar.vue"
import TextEditor from "@/components/widgets/input/TextEditor.vue"

type AddSurveyInput = {
  name: string
  description: string
  surveyType: string
  list: number | null
  markAsPublic: boolean
}

@Component({
  components: {
    TextEditor,
  },
})
export default class NewSurveyForm extends Vue {
  @Prop({ required: true }) readonly survey!: SurveyFragmentFragment

  readonly AllJobTitlesQuery = AllJobTitlesQuery
  readonly query = ListSurveyQuery
  isSaving = false

  defaultForm: AddSurveyInput = {
    name: "",
    description: "",
    surveyType: "",
    list: null,
    markAsPublic: false,
  }

  form: AddSurveyInput = { ...this.defaultForm }

  @Emit("input")
  @Emit("close")
  onCancel() {
    return false
  }

  @Emit("saving")
  emitSaving() {
    return true
  }

  get surveyTypes() {
    return [
      {
        text: "Application Form",
        value: "application_form",
      },
      {
        text: "Application Form Legacy",
        value: "application_form_legacy",
      },
      {
        text: "General Form",
        value: "general",
      },
      {
        text: "Appraisal Survey",
        value: "appraisal_survey",
      },
      {
        text: "Appraisal Survey Legacy",
        value: "appraisal_survey_legacy",
      },
    ]
  }

  @Watch("survey", { immediate: true })
  populateForm() {
    if (this.survey) {
      for (let field in this.form) {
        let current = this.survey[field as keyof SurveyFragmentFragment]
        if (current) (this.form[field as keyof AddSurveyInput] as any) = current
      }
      this.form.list = parseInt(this.survey.surveyableId)
      this.form.markAsPublic = this.survey.public
    } else {
      this.form = { ...this.defaultForm }
    }
  }

  get queryVars(): FilterType {
    return {
      paginateSurveys: { page: 1, per: 10 },
    }
  }

  updateRoute(id: string) {
    const currentUrl = window.location.href
    const newUrl = currentUrl.replace("/surveys", `/surveys/view/${id}`)
    window.history.replaceState(null, "", newUrl)
  }

  async createSurvey() {
    this.isSaving = true

    const { name, description, surveyType, list, markAsPublic } = this.form

    const result = await this.mutate<CreateSurveyMutationMutation>({
      mutation: CreateSurveyMutation,
      variables: {
        name,
        description,
        surveyType,
        public: markAsPublic,
        surveyable: {
          jobTitleId: list,
        },
      },
      done: () => (this.isSaving = false),
      refetchQueries: [
        {
          query: this.query,
          variables: {
            ...this.queryVars,
          },
        },
      ],
    })

    if (result.data?.createSurvey?.survey) {
      this.$emit("save", result.data?.createSurvey?.survey)
      this.addSuccess("Survey has been created")
      this.reset()
      this.updateRoute(result.data.createSurvey.survey.scatterId)
    }
  }

  async updateSurvey() {
    this.isSaving = true

    const { name, description, markAsPublic } = this.form

    const result = await this.mutate<UpdateSurveyMutationMutation>({
      mutation: UpdateSurveyMutation,
      variables: {
        name,
        description,
        surveyId: this.survey.id,
        public: markAsPublic,
      },
      done: () => (this.isSaving = false),
    })

    if (result.data?.updateSurvey?.survey) {
      this.$emit("save", result.data?.updateSurvey?.survey)
      this.addSuccess("Survey has been updated")
      this.reset()
    }
  }

  async onSave() {
    const validity = await (this.$refs.observer as any).validateWithInfo()

    if (!validity.isValid) return

    //Check if form input changed
    if (!validity.flags.changed) {
      this.$emit("save", this.survey)
      return
    }

    if (this.survey?.id) {
      if (validity.fields.List.changed) {
        this.addError("You cannot change the list of a survey")
        return
      }

      if (validity.fields["Survey Type"].changed) {
        this.addError("You cannot change the survey type of a survey")
        return
      }

      this.updateSurvey()
    } else {
      this.createSurvey()
    }
  }

  reset() {
    this.form = { ...this.defaultForm }
    this.$refs.observer && (this.$refs.observer as any).reset()
  }
}
