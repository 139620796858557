
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Question, OptionType } from "./QuestionList.vue"
import { SurveyQuestionTypeEnum } from "@/gql"

@Component({
  components: {},
})
export default class QuestionTypeInput extends Vue {
  @Prop({ required: true }) value!: string
  @Prop({ required: true }) question!: Question
  @Prop() options!: OptionType
  @Prop() required!: boolean

  optionState: OptionType = []

  form = {
    questionType: SurveyQuestionTypeEnum.ShortAnswer,
    required: false,
  }

  @Watch("question", { immediate: true, deep: true })
  onQuestionPropChange() {
    this.form.questionType = this.question.questionType!
    this.form.required = this.question.required!
  }

  @Watch("form.questionType")
  setOptions() {
    const questionType = this.form.questionType as string
    const optionDefaults = [
      { description: "option 1", label: "option 1", value: "option_1" },
      { description: "option 2", label: "option 2", value: "option_2" },
    ]

    switch (questionType) {
      case "short_answer":
      case "long_answer":
        this.optionState = []
        break
      case "scale":
        this.optionState = {
          min: 1,
          max: 5,
          minLabel: "Not at all",
          maxLabel: "Very much so",
          layout: "horizontal",
          customLabels: false,
          labels: {},
        }
        break
      case "scored_multiple_choice":
        this.optionState = [
          { description: "option 1", label: "option 1", value: "option_1", score: 1 },
          { description: "option 2", label: "option 2", value: "option_2", score: 2 },
        ]
        break
      default:
        this.optionState = optionDefaults
        break
    }

    this.$emit("update:options", this.optionState)
    this.$emit("save", {
      questionType: this.form.questionType,
      options: JSON.stringify(this.optionState),
    })
  }

  get questionType() {
    return [
      {
        text: "Short Answer",
        icon: "la-grip-lines",
        value: "short_answer",
      },
      {
        text: "Freeform (Legacy)",
        icon: "la-grip-lines",
        value: "freeform",
      },
      {
        text: "Long Answer",
        icon: "la-bars",
        value: "long_answer",
      },
      {
        text: "Scored Multiple Choice",
        icon: "la-sort-numeric-down",
        value: "scored_multiple_choice",
      },
      {
        text: "Multiple Choice",
        icon: "la-dot-circle",
        value: "multiple_choice",
      },
      {
        text: "Scale",
        icon: "la-ellipsis-h",
        value: "scale",
      },

      {
        text: "Dropdown",
        icon: "la-chevron-circle-down",
        value: "dropdown",
      },
      {
        text: "Checkboxes",
        icon: "la-check-square",
        value: "multi_select",
      },
    ]
  }

  getQuestionTypeObject(key: string) {
    return this.questionType.find((type) => type.value === key)
  }

  onQuestionTypeChange() {
    this.$emit("input", this.form.questionType)
  }

  onRequiredChange() {
    this.$emit("update:required", this.form.required)
    this.$emit("save", { required: this.form.required })
  }
}
